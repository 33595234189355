<template>
  <div class="dashboard">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <div class="grid">
      <NewTasksCard />
      <UserTasksCard />
      <ProjectsOrderCard v-if="isAdmin" />
      <TimeTrackingCard />
    </div>
    <div class="mt-2">
      <AppointmentsToday />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import AppointmentsToday from '@/components/Appointments/AppointmentsToday';
import NewTasksCard from '@/components/Tasks/NewTasksCard';
import UserTasksCard from '@/components/Tasks/UserTasksCard';
import ProjectsOrderCard from '@/components/Dashboards/ProjectsOrderCard';
import TimeTrackingCard from '../TimeTracking/TimeTrackingCard.vue';

export default {
  name: 'Dashboard',
  components: {
    Breadcrumbs,
    NewTasksCard,
    AppointmentsToday,
    UserTasksCard,
    ProjectsOrderCard,
    TimeTrackingCard,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['isLoading', 'getAppointments', 'getEnumValues', 'getEmployees', 'getMeta']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('dashboard') }];
    },
    appointmentStates() {
      const appointmentStates = this.getEnumValues('AppointmentState').map((appointmentState) => {
        return { value: appointmentState, label: this.$t(appointmentState) };
      });
      return appointmentStates;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    appointments() {
      return this.getAppointments;
    },
  },
  methods: {
    ...mapActions(['fetchMeta']),
  },
  async created() {},
  async mounted() {
    this.fetchMeta();
  },
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  gap: 1em;
  margin-bottom: 1em;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  background: rgba(0, 0, 32, 0.03);
  padding: 0.5em;
  // border: 1px solid red;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}
.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 0.5em;
}

.grid > div {
  padding: 0em;
  // border: 2px;
  // background: linear-gradient(180deg, #30323d 0%, #2e3542 100%);
}
.logo {
  width: 88px;
}
.data-table-container {
  overflow-x: auto;
}
</style>
