<template>
  <div class="personalTimeTracking">
    <div class="grid">
      <Card>
        <template #title
          >Zeiterfassung
          <div class="personalTimeTracking" v-if="appointment && appointment.project">
            {{ $t('time_tracking') }} bei {{ appointment.project.customer.firstname }}
            {{ appointment.project.customer.lastname }}
          </div>
        </template>
        <template #content>
          <div class="time-control-container">
            <div class="time-display">{{ duration }}</div>
            <Button
              class="btn btn-narrow btn-inverse ml-3"
              @click="toggleTimeTracking"
              :label="timeTrackingState === 'STARTED' ? $t('stop') : $t('start')"
            >
            </Button>
          </div>

          <Accordion class="mt-3">
            <AccordionTab header="Erfasste Zeiten">
              <MyTimeTrackingTable />
            </AccordionTab>
          </Accordion>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import MyTimeTrackingTable from '@/components/MyTimeTrackingTable';

export default {
  name: 'TimeTrackingCard',
  components: {
    Card,
    Button,
    Accordion,
    AccordionTab,
    MyTimeTrackingTable,
  },
  props: {
    project: { type: Object },
    appointment: { type: Object },
  },
  data() {
    return {
      startTime: null,
      stopTime: null,
      currentTime: null,
      timer: null,
      timeTrackingNumber: null,
      timeTrackingState: null,
    };
  },
  computed: {
    ...mapGetters(['getTimeTrackings', 'getTimeTrackingByNumber']),

    duration() {
      if (!this.startTime) return '00:00:00';

      const start = new Date(this.startTime).getTime();
      const end = new Date(this.currentTime).getTime();
      const diff = Math.floor((end - start) / 1000);

      const hours = String(Math.floor(diff / 3600)).padStart(2, '0');
      const minutes = String(Math.floor((diff % 3600) / 60)).padStart(2, '0');
      const seconds = String(diff % 60).padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    },
  },
  created() {
    this.loadTimerState();
    this.timeTrackingState = localStorage.getItem('timeTrackingState') || 'STOPPED';
  },
  methods: {
    ...mapActions(['createTimeTracking', 'updateTimeTracking']),
    toggleTimeTracking() {
      if (this.timeTrackingState === 'STARTED') {
        this.stopTimeTracking();
      } else {
        this.startTimeTracking();
      }
    },
    loadTimerState() {
      const storedStartTime = localStorage.getItem('startTime');
      const storedStatus = localStorage.getItem('timeTrackingState');
      const storedTimeTrackingNumber = localStorage.getItem('timeTrackingNumber');

      if (storedStartTime && storedStatus === 'STARTED') {
        this.startTime = storedStartTime;
        this.currentTime = new Date().toISOString();
        this.timeTrackingNumber = storedTimeTrackingNumber;
        this.startTimer();
      }
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime = new Date().toISOString();
      }, 1000);
    },
    refreshTable() {
      this.loadTimeTrackingData();
    },
    async startTimeTracking() {
      const now = new Date();
      now.setMilliseconds(0);
      this.startTime = now.toISOString();
      this.currentTime = now.toISOString();
      this.timeTrackingState = 'STARTED';

      localStorage.setItem('startTime', this.startTime);
      localStorage.setItem('timeTrackingState', 'STARTED');
      this.startTimer();

      const response = await this.createTimeTracking({
        project: this.project,
        appointment: this.appointment,
        startTime: this.startTime,
        stopTime: this.stopTime,
        timeTrackingState: this.timeTrackingState,
      });

      this.timeTrackingNumber = response;
      localStorage.setItem('timeTrackingNumber', this.timeTrackingNumber);
      this.$nextTick(() => {
        if (this.$refs.timeTrackingTable) {
          this.$refs.timeTrackingTable.refreshTable();
        }
      });
    },

    async stopTimeTracking() {
      clearInterval(this.timer);

      const stopTime = new Date().toISOString();
      this.stopTime = stopTime;
      this.updateTimeTrackingState('STOPPED', stopTime);

      this.timeTrackingState = 'STOPPED';

      if (this.timeTrackingNumber) {
        const timeTracking = await this.$store.getters.getTimeTrackingByNumber(
          this.timeTrackingNumber
        );
        if (timeTracking) {
          timeTracking.stopTime = stopTime;
          timeTracking.timeTrackingState = 'STOPPED';

          await this.updateTimeTracking({
            timeTracking,
          });
        }
      }
      this.$nextTick(() => {
        if (this.$refs.timeTrackingTable) {
          this.$refs.timeTrackingTable.refreshTable();
        }
      });
    },
    updateTimeTrackingState(state, stopTime = null) {
      localStorage.setItem('timeTrackingState', state);
      if (stopTime) {
        localStorage.setItem('stopTime', stopTime);
      } else {
        localStorage.removeItem('stopTime');
      }

      if (state === 'STOPPED') {
        localStorage.removeItem('timeTrackingNumber');
      }
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 0.5em;
}
.grid > div {
  padding: 1em;
  border: 2px;
}

.time-control-container {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.5rem;
}

.time-display {
  font-size: 2rem;
  margin: 0;
}

.mt-3 {
  margin-top: 1rem;
}
</style>
